const initialState = {
  keyword_value: "",
  location: [],
  location_value: "",
  nis_active: "",
  as_category: false,
  categoryID: "",
  serviceFilter: [],
  municipality: "",
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SEARCH":
      return { ...state, [`${action.key}_value`]: action.value };

    case "ADD_TO_LOCATION_SELECTION":
      for (let i = 0; i < state.location.length; i++) {
        const location = state.location[i];
        if (location.nis === action.value.nis) return state;
      }
      return { ...state, location: [...state.location, action.value] };

    case "EMPTY_LOCATION_SELECTION":
      return { ...state, location: [] };

    case "SET_LOCATION_VALUE":
      return { ...state, location_value: action.nis, nis_active: action.name };

    case "REMOVE_FROM_LOCATION_SELECTION":
      let copy = { ...state };
      for (let i = 0; i < copy.location.length; i++)
        if (copy.location[i].nis === action.id) {
          copy.location.splice(i, 1);
          break;
        }
      return copy;

    case "LAST_SEARCH":
      return { ...state, location: action.value };
    case "UPDATE_AS_CATEGORY": {
      return { ...state, as_category: action.value };
    }
    case "UPDATE_CATEGORY":
      return { ...state, categoryID: action.value };
    case "UPDATE_FILTER":
      return { ...state, serviceFilter: action.value };
    case "SET_FIRST_MUNICIPALITY":
      return { ...state, municipality: action.value };
    default:
      return state;
  }
};

export default SearchReducer;
