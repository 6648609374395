
const initialState = {
    show: false,
    view: "municipalities"
}

const FilterReducer = (state = initialState, action) => {

    switch (action.type) {
        case "TOGGLE_MAP":
            return { ...state, show: !state.show }

        case "UPDATE_MAP_VIEW":
            return { ...state, view: action.id }    

        default: return state;
    }
}

export default FilterReducer;
