
/*
    This component renders all the meta tags of the ESN project.
    It also accepts props to update the meta tags for the business page.
    This means that every business page will have unique meta tags of the enterprise
*/
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import React from "react";

const Meta = (meta) => {

    const { lang, translate, domain, nis } = useSelector(state => state.general);
    const location = useSelector(state => state.search.location);
    let name = location[0] && location[0].name
    let list = ['ternat', 'beersel', 'boutersem', 'opwijk', 'kortessem', 'wellen', 'gingelom', 'borgloon', 'denderleeuw', 'blankenberge']
    let cities = ['blankenberge', 'borgloon']

    let description = nis ? `Op zoek naar ik koop lokaal in ${nis.charAt(0).toUpperCase() + nis.slice(1)}, Dan vind je alleen op 100%lokaal alle Belgische lokale handelaars en ondernemers! ${nis && list.includes(nis) ? `Dit privacyvriendelijkproject wordt reeds ondersteund door jouw ${location.length > 0 && location[0].type === 'city' ? 'stad' : 'gemeente'}!` : `Wordt dit privacyvriendelijk project al ondersteunt door jouw ${location.length > 0 && location[0].type === 'city' ? 'stad' : 'gemeente'}?`}`
        // Op zoek naar ik koop lokaal in Aalst? Dan vind je alleen op 100%lokaal alle Belgische lokale handelaars en ondernemers!
        // let description = nis ? `100% Lokaal, zoek alle lokale ondernemers in ${nis.charAt(0).toUpperCase() + nis.slice(1)}! ${nis && list.includes(nis) ? `Dit privacyvriendelijkproject wordt reeds ondersteund door jouw ${location.length > 0 && location[0].type === 'city' ? 'stad' : 'gemeente'}!` : `Wordt dit privacyvriendelijk project al ondersteunt door jouw ${location.length > 0 && location[0].type === 'city' ? 'stad' : 'gemeente'}?`}`
        : meta.description ? meta.description : translate("esn_description")
    
        const favicon = (x) => 
            meta.title == translate("percent_local") ?
                require(`../../library/imgs/favicon/markerfavicon.ico`)
            :
                require(`../../library/imgs/favicon/${lang}/${x}`)

    
    // Dit privacyvriendelijkproject wordt reeds ondersteund door jouw stad / gemeente
    return (
        <Helmet>
            <title>{meta.title ? meta.title : `${name ? `${name.charAt(0).toUpperCase() + name.slice(1)} - ` : ""} ${nis ? translate("percent_local") : translate("esn_name")}`}</title>

            {/* Don"t keep cache */}
            <meta http-equiv="cache-control" content="no-cache" />
            <meta http-equiv="expires" content="0" />
            <meta http-equiv="pragma" content="no-cache" />

            {/* General meta tags */}
            <meta charSet="utf-8" />
            <meta name="url" content={window.location.href} />
            <meta name="robots" content="index,follow" />

            {/* Prevents Search engine (Google, Yahoo and MSN)  to display its own description taken from its directory, instead of you meta description. Using below tag would ensure that Search engine would display your meta description below search results and it would be useful to improve your CTR. */}
            <meta name="robots" content="noodp,noydir" />
            <meta name="language" content={lang} />
            <meta name="copyright" content="identityBuilding bv" />
            <meta name="category" content={meta.category ? meta.category : translate("search_engine")} />
            <meta name="description" content={description} />
            <meta name="image" content={meta.logo ? meta.logo : require(`../../library/imgs/logos/esn/logo_esn_${lang}.svg`)} />
            {/* Social media meta tags */}
            {/* https://ogp.me/ */}

            {/* General */}
            <meta name="og:title" content={meta.title ? meta.title : `${name ? `${name.charAt(0).toUpperCase() + name.slice(1)} - ` : ""} ${nis ? translate("percent_local") : translate("esn_name")}`} />
            {/* <meta name="og:description" content={meta.description ? meta.description : translate("esn_description")} /> */}
            <meta name="og:image" content={meta.logo ? meta.logo : require(`../../library/imgs/logos/esn/logo_esn_${lang}.svg`)} />

            {/* Address */}
            <meta name="og:latitude" content={meta.latitude ? meta.latitude : "50.940469"} />
            <meta name="og:longitude" content={meta.longitude ? meta.longitude : "3.954115"} />
            <meta name="og:street-address" content={meta.street ? meta.street : "Kuilstraat 1"} />
            <meta name="og:locality" content={meta.municipality ? meta.municipality : "Erondegem"} />
            {/* <meta name="og:region" content={translate("flemish_region")} /> */}
            <meta name="og:postal-code" content={meta.postal_code ? meta.postal_code : 9420} />
            <meta name="og:country-name" content={translate("belgium")} />

            {/* Facebook */}
            <meta name="fb:page_id" content="1401933736730798" />

            {/* Twitter related tags */}
            <meta name="twitter:card" content="product" />
            <meta name="twitter:site" content="https://identitybuilding.be" />
            <meta name="twitter:title" content={meta.title ? meta.title : `${name ? `${name.charAt(0).toUpperCase() + name.slice(1)} - ` : ""} ${nis ? translate("percent_local") : translate("esn_name")}`} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={meta.logo ? meta.logo : require(`../../library/imgs/logos/esn/logo_esn_${lang}.svg`)} />
            
            {/* Favicon / icon related meta tags */}
            <link rel="apple-touch-icon" sizes="180x180" href={favicon("apple-touch-icon.png")} />
            <link rel="icon" type="image/png" sizes="32x32" href={favicon("favicon-32x32.png")} />
            <link rel="icon" type="image/png" sizes="16x16" href={favicon("favicon-16x16.png")} />
            <link rel="manifest" href={favicon("site.webmanifest")} />
            <link rel="mask-icon" href={favicon("safari-pinned-tab.svg")} color="#5bbad5" />
            <link rel="shortcut icon" href={favicon("favicon.ico")} />
            <link rel="shortcut icon" href="../../library/imgs/favicon/markerfavicon.png" />
            <meta name="msapplication-TileColor" content="#ffc40d" />
            <meta name="msapplication-config" content={favicon("browserconfig.xml")} />
            <meta name="theme-color" content="#ffffff" />

            {/* plausible */}
            <script async defer data-domain={`${domain}.be`} src="https://plausible.io/js/plausible.js"></script>
            {/* <script async defer data-domain="localhost" src="https://plausible.io/js/plausible.js"></script> */}

        </Helmet>
    );
}

export default Meta;
