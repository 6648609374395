var engine = require('store/src/store-engine')
var storages = [
    require('store/storages/cookieStorage')
]
var plugins = [
    require('store/plugins/defaults'),
    require('store/plugins/expire')
]
var store = engine.createStore(storages, plugins)


const initialState = {
    btocb: 0,
    view: store.get('view') ? store.get('view') : 0
}
let expire_date = new Date().getTime() + 300000;

const FilterReducer = (state = initialState, action) => {

    switch (action.type) {
        case "TOGGLE_VIEW": {
            store.set('view', action.id, expire_date)
            return { ...state, view: action.id }
        }
        case "TOGGLE_B2BC":
            return { ...state, btocb: action.id }

        default: return state;
    }
}

export default FilterReducer;
